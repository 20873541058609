import React, { useState } from 'react';

const EmailImageUpload = () => {
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const BOT_TOKEN = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  const CHAT_ID = process.env.REACT_APP_TELEGRAM_CHAT_ID;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !image) {
      setMessage('请填写邮箱并选择图片');
      return;
    }

    setIsSubmitting(true);
    setMessage('正在提交...');

    try {
      await sendMessageWithImage(email, image);
      setMessage('补单数据已成功提交!');
      setEmail('');
      setImage(null);
    } catch (error) {
      console.error('Error:', error);
      setMessage('发送失败，请重试');
    } finally {
      setIsSubmitting(false);
    }
  };

  const sendMessageWithImage = async (email, imageFile) => {
    const formData = new FormData();
    formData.append('chat_id', CHAT_ID);
    formData.append('photo', imageFile);
    formData.append('caption', `新的提交\n邮箱: ${email}`);

    const response = await fetch(`https://api.telegram.org/bot${BOT_TOKEN}/sendPhoto`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to send message with image');
    }
  };

  return (
      <div style={{ maxWidth: '400px', margin: '40px auto', padding: '20px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div>
            <label htmlFor="email" style={{ display: 'block', marginBottom: '4px' }}>邮箱地址</label>
            <input
                id="email"
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="请输入您的YNS邮箱"
                required
                style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
          <div>
            <label htmlFor="image" style={{ display: 'block', marginBottom: '4px' }}>上传充值图片</label>
            <input
                id="image"
                type="file"
                onChange={handleImageChange}
                accept="image/*"
                required
                style={{ width: '100%' }}
            />
          </div>
          <button
              type="submit"
              disabled={isSubmitting || !email || !image}
              style={{
                padding: '8px 16px',
                backgroundColor: isSubmitting || !email || !image ? '#cccccc' : '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: isSubmitting || !email || !image ? 'not-allowed' : 'pointer'
              }}
          >
            {isSubmitting ? '提交中...' : '提交'}
          </button>
        </form>
        {message && (
            <div style={{ marginTop: '16px', padding: '8px', backgroundColor: '#f0f0f0', borderRadius: '4px' }}>
              {message}
            </div>
        )}
      </div>
  );
};

export default EmailImageUpload;